import { WatchQueryFetchPolicy, gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

const addModuleMutation = gql`
  mutation ($payload: CreateFeeDetailInput!) {
    createFeeDetail(payload: $payload) {
      id
      name
      description
      amount
      components {
        amount
        name
      }
    }
  }
`;

const createConcessionMutation = gql`
  mutation ($payload: CreateConcessionInput!) {
    createConcessionBooks(payload: $payload) {
      id
      amount
      feeBook
      name
      description
    }
  }
`;

const getFeeDetailsQuery = gql`
  query ($limit: Int, $skip: Int, $filter: FeeDetailFilterInput, $sort: FeeDetailSortInput) {
    getFeeDetails(limit: $limit, skip: $skip, filter: $filter, sort: $sort) {
      hasNextPage
      totalCount
      data {
        id
        name
        description
        amount
        isGroup
        components {
          id
          amount
          name
          description
          amount
          isGroup
          orderNo
        }
      }
    }
  }
`;

const getInstitutePaymentSummary = gql`
  query ($ay: String!) {
    getSummary(ay: $ay) {
      academicYear
      ayDetail {
        alias
      }
      totalFee
      totalPayable
      totalPaid
      totalPending
      totalConcession
    }
  }
`;

const getFeeDetailGroupQuery = gql`
  query ($limit: Int, $skip: Int, $filter: FeeDetailFilterInput) {
    getFeeDetails(limit: $limit, skip: $skip, filter: $filter) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
      }
    }
  }
`;

const getBatchSummary = gql`
  query ($ay: String!, $limit: Int, $skip: Int, $sort: BatchPaymentSummarySortInput) {
    getBatchSummary(ay: $ay, limit: $limit, skip: $skip, sort: $sort) {
      totalCount
      data {
        batchName
        batchAlias
        batch
        totalPayable
        totalFee
        totalPaid
        totalPending
        totalConcession
        academicYear
        reportRequest {
          createdAt
          status
          type
        }
        lastReportRequest {
          createdAt
          status
          type
        }
      }
    }
  }
`;

const getStudentSummary = gql`
  query ($batch: String!, $filter: StudentPaymentFilterInput, $limit: Int, $skip: Int, $status: FeePaymentStatus) {
    getStudentSummary(batch: $batch, filter: $filter, limit: $limit, skip: $skip, status: $status) {
      totalCount
      data {
        academicYear
        batchAlias
        division
        firstName
        lastName
        batchName
        totalConcession
        totalPayable
        totalFee
        totalPaid
        totalPending
        subjectGroup
        fullName
      }
    }
  }
`;

const getAccountNamesQuery = gql`
  query ($limit: Int, $skip: Int, $filters: BankDetailFilterInput) {
    bankDetails(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
      }
    }
  }
`;

const getStudentFeeBook = gql`
  query ($batch: String!, $user: String!) {
    getStudentFeeBook(batch: $batch, user: $user) {
      rollNo
      firstName
      lastName
      batchName
      division
      feeBook {
        amount
        groupId
        groupName
        totalConcessions
        payable
        paymentMode
        paymentDate
        paymentReceiver{
          id
          name
        }
        orderNo
        fees {
          id
          orderNo
          name
          amount
          payable
          concessions {
            name
            amount
          }
        }
      }
      payments {
        id
        mode
        status
        date
        detail {
          note
          paymentDate
          payId
          approvedBy
          amount
          payerBank
          transactionDoc {
            id
            name: documentName
            uri: documentUrl
          }
          donatedBy
          donatedTo
        }
        refund {
          amount
          payId
          approvedBy
        }
      }
      totalFee
      totalConcession
      totalPayable
      totalPaid
      totalPending
      payableAmount
      fees {
        installmentPayable
      }
    }
  }
`;

const getFeeBooksQuery = gql`
  query (
    $limit: Int
    $skip: Int
    $list: String
    $filter: FeesBookFilterInput
    $sort: FeesBookSortInput
  ) {
    getFeeBooks(limit: $limit, list: $list, skip: $skip, filter: $filter, sort: $sort) {
      hasNextPage
      totalCount
      data {
        id
        createdAt
        createdBy
        fee {
          id
          name
          amount
          components {
            id
            name
            amount
          }
        }
        finalFee {
          concessions {
            amount
          }
        }
        institute
        payerType
        payers {
          name
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`;

const getFeeDetailsForParticularStudent = gql`
  query ($batch: String, $user: String) {
    getStudentFeeBook(batch: $batch, user: $user) {
      user
      batch
      batchName
      feeBook {
        amount
        groupId
        groupName
        name
        totalConcessions
        payable
        paymentId
        paymentDate
        paymentMode
        orderNo
        fees {
          name
          id
          amount
          payable
          concessions {
            name
            amount
          }
          orderNo
        }
      }
      payments {
        id
        mode
        orderId
        createdAt
        detail {
          paymentDate
          payId
          amount
          approvedBy
          installmentId
          merchId
        }
        feeBook {
          amount
          groupId
          groupName
          orderNo
          fee {
            id
            name
            amount
            orderNo
          }
        }
      }
      totalPending
      totalPaid
      totalPayable
      payableAmount
      fees {
        installmentPayable
      }
    }
  }
`;

const assignModuleMutation = gql`
  mutation ($payload: CreateFeeBookInput!) {
    createFeeBooks(payload: $payload) {
      id
    }
  }
`;

const editFeeCompositionMutation = gql`
  mutation ($payload: UpdateFeeBookInput!) {
    updateFeeBooks(payload: $payload) {
      id
    }
  }
`;

const createPaymentMutation = gql`
  mutation ($payload: CreatePaymentInput!) {
    createPayment(payload: $payload) {
      date
      payer {
        id
      }
      feeBook {
        id
      }
      mode
      detail {
        payId
        approvedBy
      }
    }
  }
`;

const getBanksDetails = gql`
  query ($limit: Int, $skip: Int, $filters: BankDetailFilterInput) {
    bankDetails(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        accountNumberView
        accountType
        bankName
        beneficiaryName
        createdAt
        ifscCode
        linkStatus
        name
        razorpayRoute
        email
        id
        institute {
          id
          name
        }
        proofDocuments {
          id
          fileExtension
        }
      }
    }
  }
`;

const CreateBankAccountDetails = gql`
  mutation ($payload: BankDetailInput!) {
    createBankDetail(payload: $payload) {
      accountNumberView
      accountType
      bankName
      beneficiaryName
      createdAt
      email
      id
      ifscCode
      linkStatus
      name
    }
  }
`;

const linkAccount = gql`
  mutation ($payload: LinkBankDetailInput!) {
    linkBankDetail(payload: $payload) {
      id
    }
  }
`;

const getConcessionsQuery = gql`
  query (
    $limit: Int
    $skip: Int
    $filter: ConcessionBookFilterInput
    $sort: ConcessionBookSortInput
  ) {
    getConcessionBooks(limit: $limit, skip: $skip, filter: $filter, sort: $sort) {
      data {
        name
        id
        amount
        feeBook
      }
    }
  }
`;

const CreateOrder = gql`
  mutation ($batch: String!, $payer: String!, $fees: [String!]!) {
    createOrder(batch: $batch, payer: $payer, fees: $fees) {
      orderId
      key
    }
  }
`;

const verifyPayment = gql`
  mutation ($paymentId: String!) {
    verifyPayment(paymentId: $paymentId) {
      id
      date
      mode
    }
  }
`;

const getFeeCompositionsQuery = gql`
  query (
    $limit: Int
    $skip: Int
    $sort: FeeCompositionViewSortInput
    $filter: FeeCompositionFilterInput
    $subGroup: String
  ) {
    getFeeCompositions(limit: $limit, skip: $skip, sort: $sort, filter: $filter, subGroup: $subGroup) {
      hasNextPage
      totalCount
      data {
        id
        name
        payerType
        bankAlias
        bankId
        createdAt
        payers {
          batch
          alias
          firstName
          lastName
          user
          name
          classId
          division
          classAlias
        }
        payerList {
          name: label
          id: value
        }
        feeBooks {
          amount
          compositionId
          compositionName
          createdAt
          createdBy
          orderNo
          fee {
            amount
            components {
              id
              name
              amount
            }
            createdAt
            createdBy
            description
            id
            institute
            isGroup
            name
            parent
            type
            updatedAt
          }
          groupId
          groupName
          id
          institute
          name
          payerType
          payers {
            batch
            alias
            firstName
            lastName
            user
            name
          }
          updatedAt
        }
      }
    }
  }
`;
const getFeeCompositionByIdQuery = gql`
  query (
    $limit: Int
    $skip: Int
    $sort: FeeCompositionViewSortInput
    $id: String
  ) {
    getFeeCompositions(limit: $limit, skip: $skip, sort: $sort, compositionId: $id) {
      hasNextPage
      totalCount
      data {
        id
        name
        payerType
        bankAlias
        createdAt
        payers {
          batch
          alias
          firstName
          lastName
          user
          name
        }
        feeBooks {
          orderNo
          amount
          compositionId
          compositionName
          createdAt
          createdBy
          fee {
            orderNo
            amount
            components {
              id
              name
              amount
            }
            createdAt
            createdBy
            description
            id
            institute
            isGroup
            name
            parent
            type
            updatedAt
          }
          groupId
          groupName
          id
          institute
          name
          payerType
          payers {
            batch
            alias
            firstName
            lastName
            user
            name
          }
          updatedAt
        }
      }
    }
  }
`;

const getConcessionCompositionsQuery = gql`
  query(
    $filter: ConcessionCompositionFilterInput
    $limit: Int
    $skip: Int
    $sort: ConcessionCompositionViewSortInput
    $batch: String
    $subGroup: String
  ) {
    getConcessionCompositions(
      filter: $filter
      limit: $limit
      skip: $skip
      sort: $sort
      batch: $batch
      subGroup: $subGroup
    ) {
      data {
        name
        id
        payers {
          batch
          alias
          firstName
          lastName
          user
        }
        payerType
        concessions {
          amount
          name
          id
          createdAt
          feeBook {
            id
            amount
            groupName
            groupId
            compositionName
            fee {
              id
              parent
              amount
              name
            }
          }
          payers
        }
      }
      totalCount
    }
  }
`;

const createConcessionQuery = gql`
  mutation ($payload: CreateConcessionInput!) {
    createConcessionBooks(payload: $payload) {
      id
      amount
    }
  }
`;

const deleteFeeCompositionMutation = gql`
  mutation ($id: String!) {
    deleteFeeBook(id: $id)
  }
`;

const deleteModuleMutation = gql`
  mutation ($id: String!) {
    deleteFeeDetail(id: $id)
  }
`;

const getFilteredFeeCompositionsQuery = gql`
  query (
    $limit: Int
    $skip: Int
    $subGroup: String
  ) {
    getFeeCompositions(limit: $limit, skip: $skip, subGroup: $subGroup) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
      }
    }
  }
`;

const getApplicationFeeBookQuery = gql`
  query($applicationId: String!) {
    getApplicationFeeBook(applicationId: $applicationId) {
      feeBook {
        orderNo
        groupId
        groupName
        amount
        payable
        fees {
          id
          orderNo
          name
          amount
          payable
          concessions {
            id
            name
            amount
          }
        }
      }
      totalConcession
      totalFee
      totalPayable
      totalPending
      totalPaid
    }
  }
`;

const createStudentFeePaymentMutation = gql`
  mutation($studentId: String!) {
    generateStudentPaymentOrder(studentId: $studentId) {
      token
      merchId
      amount
      custEmail
      custContact
    }
  }
`;

const createStudentPaymentMutation = gql`
  mutation (
    $studentId: String!,
    $paymentDetail: CreatePaymentDetailInput
  ) {
    createStudentPayment(
      studentId: $studentId,
      paymentDetail: $paymentDetail,
    ) {
      id
    }
  }
`;

const generatePaymentReportsMutation = gql`
  mutation ($batch: String!) {
    generatePaymentReports (batch: $batch) {
      message
      status
    }
  }
`;

const generatePaymentReportsDivisionWiseMutation = gql`
  mutation ($batch: String!) {
    generatePaymentReportsDivisionWise (batch: $batch) {
      message
      status
    }
  }
`;

const getPendingPaymentQuery = gql`
  query ($limit: Int, $skip: Int, $sort: PaymentSortInput) {
    pendingPayment (limit: $limit, skip: $skip, sort: $sort) {
      admissionFee {
        className
        type
      }
      courseFee {
        className
        type
      }
    }
  }
`;

const createPartialPaymentForStudentMutation = gql`
  mutation ($amount: Float!, $batchId: String!, $studentId: String!) {
    createPartialPaymentForStudent (amount: $amount, batchId: $batchId, studentId: $studentId) {
      id
    }
  }
`;

const createCustomFeeMutation = gql`
  mutation ($payload: CustomFeeInput!) {
    createCustomFee (payload: $payload) {
      id
    }
  }
`;

const getCustomFeeQuery = gql`
  query ($id: String!) {
    customFee (id: $id) {
      id
      name
      amount
      merchant {
        label
        value
      }
      payerType
    }
  }
`;

const getCustomFeesQuery = gql`
  query ($filters: CustomFeeFilterInput, $limit: Int, $skip: Int, $sort: CustomFeeSortInput) {
    customFees (filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        id
        name
        amount
        merchant {
          label
          value
        }
        payerType
        status
      }
      hasNextPage
      totalCount
    }
  }
`;

const getCustomFeePayersQuery = gql`
  query ($filters: CustomFeePayerFilterInput, $customFee: String!, $limit: Int, $skip: Int, $sort: CustomFeePayerSortInput) {
    customFeePayers (filters: $filters, customFee: $customFee, limit: $limit, skip: $skip, sort: $sort) {
      data {
        id
        name
        email
        mobileNumber
        amount
        code
        status
        payments
        uniqueId
        mailSentCount
        codeStatus
        remark
      }
      hasNextPage
      totalCount
    }
  }
`;

export const reconcileCustomFeePaymentMutation = gql`
  mutation ($code: String!) {
    reconcileCustomFeePayment(code: $code) {
      message
      status
    }
  }
`;

export const getCustomFeePaymentReconcileRequestQuery = gql`
  query ($code: String!) {
    getCustomFeePaymentReconcileRequest(code: $code) {
      createdAt
      status
      errMessage
    }
  }
`;

const getFeeDetailByCodeQuery = gql`
  query ($code: String!, $limit: Int, $skip: Int, $sort: CustomFeePayerSortInput) {
    feeDetailByCode (code: $code, limit: $limit, skip: $skip, sort: $sort) {
      id
      name
      email
      amount
      customFeeName
    }
  }
`;

const createCustomFeePaymentMutation = gql`
  mutation(
    $code: String!
    $paymentDetail: CreatePaymentDetailInput
  ) {
    createCustomFeePayment(
      code: $code
      paymentDetail: $paymentDetail
    ) {
      id
    }
  }
`;

const editPaymentDetailsMutation = gql`
  mutation (
    $paymentId: String!
    $paymentDetail: UpdatePaymentDetailInput
  ) {
    updatePaymentDetails(
      paymentId: $paymentId
      paymentDetail: $paymentDetail
    ) {
      id
    }
  }
`;

const importPayerInFeeMutation = gql`
  mutation ($feeId: String!, $file: Upload!) {
    importPayerInFee(feeId: $feeId, file: $file) {
      id
      name
    }
  }
`;

const refundPaymentMutation = gql`
  mutation ($paymentId: String!) {
    refundPayment(paymentId: $paymentId) {
      id
    }
  }
`;

export const bulkReconcileCustomFeePaymentMutation = gql`
  mutation ($customFeeId: String!) {
    bulkReconcileCustomFeePayment(customFeeId: $customFeeId) {
      message
      status
    }
  }
`;

const resendMailToCustomFeePayersMutation = gql`
  mutation ($customFeeId: String!) {
    reSendMailToCustomFeePayers(customFeeId: $customFeeId) {
      message
      status
    }
  }
`;

const resendMailToCustomFeePayerMutation = gql`
  mutation ($feePayerId: String!) {
    reSendMailToCustomFeePayer(feePayerId: $feePayerId) {
      message
      status
    }
  }
`;

const expireCustomFeeMutation = gql`
  mutation ($customFeeId: String!) {
    expireCustomFee(customFeeId: $customFeeId) {
      id
    }
  }
`;

const expireFeePayerInviteMutation = gql`
  mutation ($payerId: String!) {
    expireFeePayerInvite(payerId: $payerId) {
      message
      status
    }
  }
`;

const reactivateFeePayerInviteMutation = gql`
  mutation ($payerId: String!) {
    reActivateFeePayerInvite(payerId: $payerId) {
      message
      status
    }
  }
`;

const editCustomFeePayerMutation = gql`
  mutation ($payload: UpdateCustomFeePayerInput!) {
    updateCustomFeePayer(payload: $payload) {
      id
    }
  }
`;

export {
  getFeeDetailsQuery,
  getFeeDetailGroupQuery,
  getFeeBooksQuery,
  getAccountNamesQuery,
  CreateBankAccountDetails,
  getBanksDetails,
  getInstitutePaymentSummary,
  getBatchSummary,
  getStudentSummary,
  getStudentFeeBook,
  getConcessionsQuery,
  getFeeDetailsForParticularStudent,
  getFeeCompositionsQuery,
  verifyPayment,
  CreateOrder,
  getConcessionCompositionsQuery,
  createConcessionQuery,
  getFilteredFeeCompositionsQuery,
  getApplicationFeeBookQuery,
  getFeeCompositionByIdQuery,
};

export function useQueryFeeDetails(queries: any, fetchPolicy: WatchQueryFetchPolicy = "network-only") {
  const [query, { loading, data, refetch }] = useLazyQuery(queries, { fetchPolicy: fetchPolicy });
  return { query, data, loading, refetch };
}

export function useQueryFeeBooks(queries: any, fetchPolicy: WatchQueryFetchPolicy = "network-only") {
  const [query, { loading, data, refetch }] = useLazyQuery(queries, { fetchPolicy: fetchPolicy });
  return { query, data, loading, refetch };
}

export function useAddModuleMutation() {
  const [mutate, { data: moduleData, error: moduleError }] = useMutation(addModuleMutation);
  return { mutate, moduleData, moduleError };
}

export function useAssignModuleMutation() {
  const [mutate, { data: moduleData, error: moduleError }] = useMutation(assignModuleMutation);
  return { mutate, moduleData, moduleError };
}

export function useEditFeeCompositionMutation() {
  const [mutate, { data: editFeeCompositionData, error: editFeeCompositionError }] = useMutation(editFeeCompositionMutation);
  return { mutate, editFeeCompositionData, editFeeCompositionError };
}

export function useQueryBankDetails(queries: any, fetchPolicy = 'network-only') {
  const [query, { loading, data }] = useLazyQuery(queries, { fetchPolicy: fetchPolicy });
  return { query, data, loading };
}

export function usePaymentSummary() {
  const {
    data: paymentSummary,
    loading,
    error: paymentSummaryError,
  } = useQuery(getInstitutePaymentSummary);
  return { paymentSummary, loading, paymentSummaryError };
}

export function useLinkAccountMutation() {
  const [mutate, { data: bankData, error: bankDataError }] = useMutation(linkAccount);
  return { mutate, bankData, bankDataError };
}

export function useCreatePaymentConcession() {
  const [createConcession, { error: createConcessionError }] =
    useMutation(createConcessionMutation);
  return { createConcession, createConcessionError };
}
export function useCreatePayment() {
  const [createPayment, { error: createPaymentError }] = useMutation(createPaymentMutation);
  return { createPayment, createPaymentError };
}

export function useQueryFeeComposition(queries: any, fetchPolicy: WatchQueryFetchPolicy = "network-only") {
  const [query, { loading, data, refetch }] = useLazyQuery(queries, { fetchPolicy: fetchPolicy });
  return { query, data, loading, refetch };
}

export function useQueryFeeCompositionById(fetchPolicy = 'cache-first') {
  const [getFeeCompositionById, { loading, data: feeComposition }] = useLazyQuery(
    getFeeCompositionByIdQuery,
    { fetchPolicy },
  );
  return { getFeeCompositionById, feeComposition, loading };
}

export function useQueryBatchSummary(queries: any, fetchPolicy = 'cache-first') {
  const [query, { loading, data, refetch }] = useLazyQuery(queries, { fetchPolicy: fetchPolicy });
  return { query, data, loading, refetch };
}

export function useQueryFetchConcessions() {
  const [query, { loading, data }] = useLazyQuery(
    getConcessionCompositionsQuery,
    { fetchPolicy: "network-only" }
  );
  return { query, data, loading };
}

export function useQueryStudentFeeBooks(queries: any, fetchPolicy = 'network-only') {
  const [query, { loading, data, error }] = useLazyQuery(queries, { fetchPolicy: fetchPolicy });
  return { query, data, loading, error };
}

export function useDeleteFeeComposition() {
  const [deleteFeeComposition, { data: deleteFeeCompositionData, error: deleteFeeCompositionError }] =
    useMutation(deleteFeeCompositionMutation);
  return { deleteFeeComposition, deleteFeeCompositionData, deleteFeeCompositionError };
}

export function useDeleteModule() {
  const [deleteModule, { data: deleteModuleData, error: deleteModuleError }] =
    useMutation(deleteModuleMutation);
  return { deleteModule, deleteModuleData, deleteModuleError };
}

export function useQueryApplicationFeeBooks(queries: any, fetchPolicy = 'network-only') {
  const [query, { loading, data, error }] = useLazyQuery(queries, { fetchPolicy: fetchPolicy });
  return { query, data, loading, error };
}

export function useCreateStudentFeePayment() {
  const [createStudentFeePayment, { error: createStudentFeePaymentError, data: createStudentFeePaymentData }] = useMutation(createStudentFeePaymentMutation);
  return { createStudentFeePayment, createStudentFeePaymentData, createStudentFeePaymentError };
};

export function useCreateStudentPayment() {
  const [createStudentPayment, { error: createStudentPaymentError }] = useMutation(createStudentPaymentMutation);
  return { createStudentPayment, createStudentPaymentError };
}

export function useGeneratePaymentReports() {
  const [generatePaymentReports, { error: generatePaymentReportsError }] = useMutation(generatePaymentReportsMutation);
  return { generatePaymentReports, generatePaymentReportsError };
}

export function useGeneratePaymentReportsDivisionWise() {
  const [generatePaymentReportsDivisionWise, { error: generatePaymentReportsDivisionWiseError }] = useMutation(generatePaymentReportsDivisionWiseMutation);
  return { generatePaymentReportsDivisionWise, generatePaymentReportsDivisionWiseError };
}

export function useGetPendingPayment() {
  const [
    getPendingPayment,
    {
      loading: pendingPaymentLoading,
      data: pendingPaymentData,
      error: pendingPaymentError,
      refetch: refetchPendingPayment,
    },
  ] = useLazyQuery(getPendingPaymentQuery, { fetchPolicy: 'network-only' });
  return { getPendingPayment, pendingPaymentData, pendingPaymentError, pendingPaymentLoading, refetchPendingPayment };
}

export function useCreatePartialPaymentForStudent() {
  const [createPartialPaymentForStudent] = useMutation(
    createPartialPaymentForStudentMutation
  );
  return { createPartialPaymentForStudent };
}

export function useCreateCustomFee() {
  const [createCustomFee, { data: createCustomFeeData, error: createCustomFeeError }] = useMutation(createCustomFeeMutation);
  return { createCustomFee, createCustomFeeData, createCustomFeeError };
};

export function useGetCustomFee() {
  const [
    getCustomFee,
    {
      data: customFeeData,
      loading: customFeeLoading,
      error: customFeeError,
      refetch: refetchCustomFee,
    },
  ] = useLazyQuery(getCustomFeeQuery, { fetchPolicy: 'network-only' });
  return { getCustomFee, customFeeData, customFeeLoading, customFeeError, refetchCustomFee };
}

export function useGetCustomFees() {
  const [
    getCustomFees,
    {
      data: customFeesData,
      loading: customFeesLoading,
      error: customFeesError,
      refetch: refetchCustomFees,
    },
  ] = useLazyQuery(getCustomFeesQuery, { fetchPolicy: 'network-only' });
  return { getCustomFees, customFeesData, customFeesLoading, customFeesError, refetchCustomFees };
}

export function useGetCustomFeePayers() {
  const [
    getCustomFeePayers,
    {
      data: customFeePayersData,
      loading: customFeePayersLoading,
      error: customFeePayersError,
      refetch: refetchCustomFeePayers,
    },
  ] = useLazyQuery(getCustomFeePayersQuery, { fetchPolicy: 'network-only' });
  return { getCustomFeePayers, customFeePayersData, customFeePayersLoading, customFeePayersError, refetchCustomFeePayers };
}

export function useReconcileCustomFeePayment() {
  const [reconcileCustomFeePayment, { data: reconcileCustomFeePaymentData, error: reconcileCustomFeePaymentError }] =
    useMutation(reconcileCustomFeePaymentMutation);
  return { reconcileCustomFeePayment, reconcileCustomFeePaymentData, reconcileCustomFeePaymentError };
}

export function useGetFeeDetailByCode() {
  const [
    getFeeDetailByCode,
    {
      data: feeDetailByCodeData,
      loading: feeDetailByCodeLoading,
      error: feeDetailByCodeError,
      refetch: refetchfeeDetailByCode,
    },
  ] = useLazyQuery(getFeeDetailByCodeQuery, { fetchPolicy: 'network-only' });
  return { getFeeDetailByCode, feeDetailByCodeData, feeDetailByCodeLoading, feeDetailByCodeError, refetchfeeDetailByCode };
}

export function useCreateCustomFeePayment() {
  const [createCustomFeePayment, { error: createCustomFeePaymentError }] = useMutation(createCustomFeePaymentMutation);
  return { createCustomFeePayment, createCustomFeePaymentError };
};

export function useImportPayerInFee() {
  const [importPayerInFee, { data: importPayerInFeeData, error: importPayerInFeeError }] =
    useMutation(importPayerInFeeMutation);
  return { importPayerInFee, importPayerInFeeData };
}

export function useEditPaymentDetails() {
  const [editPaymentDetails, { error: editPaymentDetailsError }] = useMutation(editPaymentDetailsMutation);
  return { editPaymentDetails, editPaymentDetailsError };
}

export function useRefundPayment() {
  const [refundPayment, { error: refundPaymentError }] = useMutation(refundPaymentMutation);
  return { refundPayment, refundPaymentError };
}

export function useBulkReconcileCustomFeePayment() {
  const [bulkReconcileCustomFeePayment, { data: bulkReconcileCustomFeePaymentData, error: bulkReconcileCustomFeePaymentError }] =
    useMutation(bulkReconcileCustomFeePaymentMutation);
  return { bulkReconcileCustomFeePayment, bulkReconcileCustomFeePaymentData, bulkReconcileCustomFeePaymentError };
}

export function useResendMailToCustomFeePayers() {
  const [resendMailToCustomFeePayers, { data: resendMailToCustomFeePayersData, error: resendMailToCustomFeePayersError }] = useMutation(resendMailToCustomFeePayersMutation);
  return { resendMailToCustomFeePayers, resendMailToCustomFeePayersData, resendMailToCustomFeePayersError };
};

export function useResendMailToCustomFeePayer() {
  const [resendMailToCustomFeePayer, { data: resendMailToCustomFeePayerData, error: resendMailToCustomFeePayerError }] = useMutation(resendMailToCustomFeePayerMutation);
  return { resendMailToCustomFeePayer, resendMailToCustomFeePayerData, resendMailToCustomFeePayerError };
};

export function useExpireCustomFee() {
  const [expireCustomFee, { data: expireCustomFeeData, error: expireCustomFeeError }] = useMutation(expireCustomFeeMutation);
  return { expireCustomFee, expireCustomFeeData, expireCustomFeeError };
}

export function useExpireFeePayerInvite() {
  const [expireFeePayerInvite, { data: expireFeePayerInviteData, error: expireFeePayerInviteError }] = useMutation(expireFeePayerInviteMutation);
  return { expireFeePayerInvite, expireFeePayerInviteData, expireFeePayerInviteError };
}

export function useReactivateFeePayerInvite() {
  const [reactivateFeePayerInvite, { data: reactivateFeePayerInviteData, error: reactivateFeePayerInviteError }] = useMutation(reactivateFeePayerInviteMutation);
  return { reactivateFeePayerInvite, reactivateFeePayerInviteData, reactivateFeePayerInviteError };
}

export function useEditCustomFeePayer() {
  const [editCustomFeePayer, { data: editCustomFeePayerData, error: editCustomFeePayerError }] = useMutation(editCustomFeePayerMutation);
  return { editCustomFeePayer, editCustomFeePayerData, editCustomFeePayerError };
}