import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { readCacheQuery, writeCacheQuery } from '../util';

const updateInstituteEmployeeDetailMutation = gql`
  mutation ($payload: UpdateInstituteEmployeeDetailInput, $remove: RemoveInstituteEmployeeDetailInput) {
    updateInstituteEmployeeDetail(payload: $payload, remove: $remove) {
      status
      message
    }
  }
`;

const removeEmployeeDetailMutation = gql`
  mutation ($remove: RemoveInstituteEmployeeDetailInput!) {
    updateInstituteEmployeeDetail(remove: $remove) {
      status
      message
    }
  }
`;

const instituteEmployeeQuery = gql`
  query ($employeeId: String!) {
    instituteEmployee(employeeId: $employeeId) {
      id
      firstName
      lastName
      type
      totalOnboardingCompletion
      onboardingCompletion {
        personalDetails
        educationDetails
        experienceDetails
        otherDetails
        addressDetails
      }
      educationDetails {
        id
        course
        institute
        institution
        udiseNo
        qualificationRef {
          id
          name
          level
        }
        paassingYear
        university
        gradeObtained
        totalMarks
        marksObtained
        averageMarks
        streams {
          value: id
          label: name
        }
        documents {
          id
          documentName
          documentUrl
          fileType
          category
        }
      }
    }
  }
`;

const instituteEmployeeExperienceQuery = gql`
  query ($employeeId: String!) {
    instituteEmployee(employeeId: $employeeId) {
      id
      experienceDetails {
        id
        employer
        belongsTo
        designation
        doj
        dol
        income
        jobtype
        documents {
          id
          documentName
          documentUrl
          fileType
          category
        }
      }
    }
  }
`;

const employeePersonalInformationQuery = gql`
  query($employeeId: String!) {
    instituteEmployee(employeeId: $employeeId) {
      employeeId
      userName
      firstName
      lastName
      profilePicture {
        id
        documentUrl
        documentName
        category
        type
      }
      draftTotalOnboardingCompletion
      totalOnboardingCompletion
      onboardingCompletion {
        personalDetails
        educationDetails
        experienceDetails
        otherDetails
        addressDetails
      }
      personalDetails {
        status
        firstName
        lastName
        middleName
        fullName
        gender
        dob
        placeOfBirth
        bloodGroup
        maritalStatus
        mobileNumber
        alternateContactNumber
        alternateContactPerson
        email
        alternateEmail
        profilePicture {
          id
          documentUrl
          documentName
          category
          type
        }
        draft {
          detail {
            firstName
            lastName
            middleName
            fullName
            gender
            dob
            placeOfBirth
            bloodGroup
            maritalStatus
            mobileNumber
            alternateContactNumber
            alternateContactPerson
            email
            alternateEmail
            profilePicture {
              id
              documentUrl
              documentName
              category
              type
            }
          }
        }
      }
      institute {
        type
        name
      }
      type
      id
      currentAddressDetail
      permanentAddressDetail
      addressDetails {
        current {
          status
          id
          area
          houseNo
          street
          zipcode
          city
          state
          draft {
            detail {
              id
              area
              houseNo
              street
              zipcode
              city
              state
            }
          }
        }
        permanent {
          status
          id
          area
          houseNo
          street
          zipcode
          city
          state
          draft {
            detail {
              id
              area
              houseNo
              street
              zipcode
              city
              state
            }
          }
        }
        isSameAsCurrent
      }
      experienceDetails {
        status
        id
        employer
        designation
        doj
        dol
        income
        jobtype
        documents {
          id
          name: documentName
          uri: documentUrl
          category
          type
        }
        draft {
          id
          detail {
            status
            id
            employer
            designation
            doj
            dol
            income
            jobtype
            documents {
              id
              name: documentName
              uri: documentUrl
              category
              type
            }
          }
        }
      }
      otherDetails {
        status
        id
        category {
          value: id
          label: name
          alias
        }
        caste
        religion
        isPhysicallyChallenged
        physicallyChallengedDescription
        aadhaarId
        nationality
        motherTongue
        languageSpoken
        documents {
          id
          uri: documentUrl
          name: documentName
          type
        }
        allDocument {
          type {
            typeId: id
            name
            label: name
            category
            defaultUserSelectionRequired
          }
          document {
            id
            category
            name: documentName
            uri: documentUrl
            fileExtension
            fileType
            typeRef
          }
        }
        draft {
          detail {
            id
            aadhaarId
            category {
              value: id
              label: name
              alias
            }
            caste
            religion
            isPhysicallyChallenged
            physicallyChallengedDescription
            nationality
            motherTongue
            languageSpoken
            documents {
              id
              uri: documentUrl
              name: documentName
              type
            }
            allDocument {
              type {
                typeId: id
                name
                label: name
                category
                defaultUserSelectionRequired
              }
              document {
                id
                category
                name: documentName
                uri: documentUrl
                fileExtension
                fileType
                typeRef
              }
            }
          }
        }
      }
      educationDetails {
        passedInFirstAttempt
        status
        id
        course
        streams {
          value: id
          label: name
        }
        gradeObtained
        paassingYear
        institution
        udiseNo
        qualificationRef {
          id
          name
          level
        }
        marksObtained
        totalMarks
        averageMarks
        university
        documents {
          id
          uri: documentUrl
          name: documentName
          type
        }
        allDocument {
          type {
            typeId: id
            name
            label: name
            category
            defaultUserSelectionRequired
          }
          document {
            id
            category
            name: documentName
            uri: documentUrl
            fileExtension
            fileType
            typeRef
          }
        }
        draft {
          id
          detail {
            passedInFirstAttempt
            status
            id
            course
            streams {
              value: id
              label: name
            }
            gradeObtained
            paassingYear
            institution
            udiseNo
            qualificationRef {
              id
              name
              level
            }
            marksObtained
            totalMarks
            university
            documents {
              id
              uri: documentUrl
              name: documentName
              type
            }
            allDocument {
              type {
                typeId: id
                name
                label: name
                category
                defaultUserSelectionRequired
              }
              document {
                id
                category
                name: documentName
                uri: documentUrl
                fileExtension
                fileType
                typeRef
              }
            }
          }
        }
      }
      status
    }
  }
`;

const employeeAddressInformationQuery = gql`
  query ($employeeId: String!) {
    instituteEmployee(employeeId: $employeeId) {
      id
      currentAddressDetail {
        id
        area
        houseNo
        street
        zipcode
        city
        state
      }
      permanentAddressDetail {
        id
        area
        houseNo
        street
        zipcode
        city
        state
      }
    }
  }
`;

const employeeOtherDetailsQuery = gql`
  query ($employeeId: String!) {
    instituteEmployee(employeeId: $employeeId) {
      id
      otherDetails {
        id
        category {
          value: id
          label: name
          alias
        }
        caste
        religion
        isPhysicallyChallenged
        allDocument {
          type {
            typeId: id
            name
            label: name
            category
          }
          document {
            id
            category
            name: documentName
            uri: documentUrl
            fileExtension
            fileType
            typeRef
          }
        }
        documents {
          id
          uri: documentUrl
          name: documentName
          type
        }
      }
    }
  }
`;

const assignAdminRoleToEmployeeQuery = gql`
  mutation ($assign: Boolean, $userId: String!) {
    assingAdminRoleToEmployee(assign: $assign, userId: $userId) {
      status
      message
    }
  }
`;

export {
  updateInstituteEmployeeDetailMutation,
  instituteEmployeeQuery,
  instituteEmployeeExperienceQuery,
  employeePersonalInformationQuery,
  employeeAddressInformationQuery,
  employeeOtherDetailsQuery,
};

export function useUpdateInstituteEmployee() {
  const [updateInstituteEmployeeDetail, { loading, error: updateInstituteEmployeeDetailError }] =
    useMutation(updateInstituteEmployeeDetailMutation);
  return { updateInstituteEmployeeDetail, updateInstituteEmployeeDetailError };
}

export function useDeleteEmployeeAcademicDocument() {
  const [deleteEmployeeAcademicDetail, { error: deleteEmployeeAcademicDocumentError }] =
    useMutation(removeEmployeeDetailMutation);
  return { deleteEmployeeAcademicDetail, deleteEmployeeAcademicDocumentError };
}

export function useDeleteEmployeeAcademicDetail() {
  const [deleteEmployeeAcademicDetail, { error: deleteEmployeeAcademicDetailError }] = useMutation(
    removeEmployeeDetailMutation,
  );
  return { deleteEmployeeAcademicDetail, deleteEmployeeAcademicDetailError };
}

export function useRemoveEmployeeDetail() {
  const [removeEmployeeDetail, { error: removeEmployeeDetailError }] = useMutation(
    removeEmployeeDetailMutation,
  );
  return { removeEmployeeDetail, removeEmployeeDetailError };
}

export function useDeleteEmployeeExperienceDetail() {
  const [deleteEmployeeExperienceDetail, { error: deleteEmployeeExperienceDetailError }] =
    useMutation(removeEmployeeDetailMutation);
  return { deleteEmployeeExperienceDetail, deleteEmployeeExperienceDetailError };
}

export function useAssignAdminRoleToEmployee() {
  const [assignAdminRoleToEmployee,  { loading, error: assignAdminRoleToEmployeeError }] = 
    useMutation(assignAdminRoleToEmployeeQuery);
  return { assignAdminRoleToEmployee, assignAdminRoleToEmployeeError };
}